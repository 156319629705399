<template>
  <div />
</template>

<script>
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import Toastification from '@/services/Toastification'

export default {
  async created() {
    let toastObject
    const token = this.$store.getters['heroAiAuthentications/sanctumToken']
    const axiosConfig = AxiosConfig.getJsonWithToken(token)

    try {
      const response = await axios.post('logout', null, axiosConfig)

      toastObject = Toastification.getContentSuccess(response.data.message)
      this.$toast(toastObject)
    } catch (error) {
      toastObject = Toastification.getContentError(error.response.data.message)
      // this.$toast(toastObject)
    }

    // เก็บค่า UserType ของผู้ใช้ก่อนจะถูกล้าง
    const userType = this.$store.getters['heroAiAuthentications/getUserType']

    this.$store.commit('heroAiAuthentications/doLogout')
    this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])

    if (userType === 'Internal') {
      await this.$router.push({ name: 'heroai-login' })
    } else {
      await this.$router.push({ name: 'heroai-client-login' })
    }
  },
}
</script>
